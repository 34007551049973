<template>
    <v-container>
        <v-row class="my-10">
            <v-col cols="12">
                <div
                    class="d-flex align-center justify-space-between mb-10"
                >
                    <h2 class="primary--text main-heading">
                        {{ $t("SME.MySolutions.pageTitle") }}
                    </h2>
                    <v-divider class="mx-4" inset vertical> </v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary primary--text"
                        dark
                        class="hover mb-2 pa-7"
                        :to="createSolution"
                    >
                        <v-icon small left color="black"> fa fa-plus </v-icon>
                        {{ $t("SME.MySolutions.pageCreateSolution") }}
                    </v-btn>
                </div>
                <v-row
                    v-if="
                        getUserSolutionsStatus_Idle ||
                        getUserSolutionsStatus_Pending
                    "
                >
                    <v-col
                        v-for="item in calculateSkeletons"
                        :key="item"
                        class="mx-auto"
                        cols="12"
                    >
                        <!-- md="4"
                        sm="12" -->
                        <v-skeleton-loader
                            type="divider, image, list-item-three-line, list-item-three-line, card-heading, actions"
                        >
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
                <h3
                    v-else-if="getUserSolutionsStatus_Error"
                    class="text-center main-heading error--text"
                >
                    {{ $t("SME.MySolutions.loadSolutionsError") }}
                </h3>
                <div 
                  v-else-if="userSolutions.length == 0"
                  class="d-flex align-center flex-column justify-center">
                  <h3 class="main-heading mb-5">
                    {{ $t("SME.MySolutions.noSolutionsFound") }}
                  </h3>
                  <p class="mb-10 error--text text-center">
                    {{ $t(`SME.MySolutions.suggestCreateSolution`) }}
                  </p>
                  <v-img
                    src="../../assets/noData.svg"
                    width="250px"
                    height="250px"
                    contain
                  >
                  </v-img>
                </div>
                <div
                    v-else
                    class="gap d-flex flex-column"
                >
                <!-- d-flex flex-wrap justify-space-around -->
                    <solution-card
                        v-for="(solution, index) in userSolutions"
                        :key="index"
                        :solution="solution"
                        class="mx-3"
                        @solution-deleted="solutionDeleted($event)"
                    >
                    </solution-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { getUserSolutions } from "@/api/smeSolutionsApi.js";
import SolutionCard from "./components/SolutionCard.vue";
// import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
    name: "MySolutions",
    components: {
        SolutionCard,
        // LoadingSpinner,
    },
    data() {
        return {
            getUserSolutionsStatus: apiStatus.Idle,
            show: false,
            userSolutions: [],
        };
    },
    computed: {
        ...apiStatusComputed([
            "getUserSolutionsStatus",
            "deleteUserSolutionStatus",
        ]),
        calculateSkeletons() {
            return this.userSolutions.length;
        },
        createSolution() {
            return { name: "Create - Edit Solution" };
        },
    },
    methods: {
        async fetchUserSolutions() {
            this.getUserSolutionsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getUserSolutions);

            if (error) {
                this.getUserSolutionsStatus = apiStatus.Error;
                return;
            }
            setTimeout(() => {
                this.getUserSolutionsStatus = apiStatus.Success;
            }, 300);
            this.userSolutions = response.data.data;
        },
        solutionDeleted() {
            this.fetchUserSolutions();
        },
    },
    created() {
        this.fetchUserSolutions();
    },
};
</script>

<style scoped>
.gap {
    gap: 3.5rem;
}
</style>
