<template>
    <div
       class="d-md-flex flex-wrap"
       >
        <v-card
          class="shadow"
          width="1500"
        >
        <div class="image-wrapper">
          <div class="image-container">
            <v-img
              :src="`${solution.image_url}`"
              alt="solution-image"
              height="200"
              class="image-itself"
            >
            </v-img>
          </div>
        </div>

        <!-- <v-card-title class="mb-3 title-overflow d-flex">
          <v-icon
           small 
           left
           >
            fa-solid fa-robot
           </v-icon>
          {{ solution.title || 'Title is not provided' }}
        </v-card-title> -->
        <div class="ml-2 mb-3 d-flex">
          <v-icon
           small 
           right
           >
            fa-solid fa-robot
           </v-icon>
          <v-card-title class="title-overflow">{{ solution.title || 'Title is not provided' }}</v-card-title>
        </div>

        <v-card-subtitle class="description-overflow">
            {{ solution.description || 'Description is not provided' }}
        </v-card-subtitle>
        <v-container class="d-flex flex-wrap">
            <v-chip-group
             center-active
             show-arrows
            >
              <v-chip
                v-for="(tag) in solution.tags"
                :key="tag.id"
                color="purple secondary--text"
                min-width="50"
                max-width="150"
                >
                <v-icon 
                x-small
                left
                >
                fa-solid fa-tag
                </v-icon>
                {{ tag.tag.charAt(0).toUpperCase() + tag.tag.slice(1) }}
              </v-chip>
            </v-chip-group>
        </v-container>

        <v-card-subtitle class="d-flex website-link-overflow">
          <v-icon 
           small      
           >
            fa-solid fa-link
          </v-icon>
            <v-btn
              :href="`${solution.website_url}`" 
              target="_blank"
              depressed
              color="transparent"
            >
             {{ solution.website_url || 'Website link is not provided' }}
            </v-btn>
        </v-card-subtitle>

        <v-card-actions>
        <v-icon 
         small
         left
         class="ml-2"
        >
         fa-solid fa-hashtag
        </v-icon>
        <div 
          v-if="solution.links.length > 0" 
          class="d-flex"
        >
         <a
          v-for="link in solution.links"
          :key="link.id" 
          :href="link.link"
          target="_blank"
         >
          <v-img
            max-width="30px"
            height="30px"
            class="mr-2 mx-2 scale"
            :src="findIcon(link)"
          >
          </v-img>
         </a>
        </div>
        <span 
          v-else
          class="ml-2 error--text font-weight-light text-caption"
        >
          {{ $t(`SME.SolutionCard.notProvidedSocialLinks`) }}
        </span>
        </v-card-actions>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions 
         class="d-flex justify-lg-space-between"
         >
          <v-btn
            class="pa-5 pr-8 button-hover"
            color="primary"
            text
            outlined
            depressed
            @click="editSolution(solution)"
          >
          <v-icon 
            x-small 
            left
            >
             fa-solid fa-pencil
            </v-icon>
            {{ $t('SME.SolutionCard.editButton') }}
          </v-btn>
          <v-btn
            class="pa-5 pr-8"
            color="error"
            text
            outlined
            depressed
            @click="deleteSolution()"
          >
          <v-icon
            x-small
            left
          >
            fa-solid fa-trash
          </v-icon>
            {{ $t('SME.SolutionCard.deleteButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <confirm-modal
       :open="openModal"
       :close.sync="openModal"
      >
        <template #content>
          <span class="modal-content">
            {{ $t(`SME.SolutionCard.solutionDeleteModal.confirmText`) }}
          </span>
        </template>
        <template #action>
          <v-btn
            depressed
            dense
            color="error lighten-1"
            class="mr-2"
            :loading="false"
            @click="deleteUserSolution(solution)"
          >
            {{ $t(`SME.SolutionCard.solutionDeleteModal.confirmDelete`) }}
          </v-btn>
        </template>
      </confirm-modal>
    </div>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { deleteSolution } from "@/api/smeSolutionsApi.js";
import ConfirmModal from "@/components/ConfirmModal"

export default {
    name: "SolutionCard",
    props: {
        solution: {
            type: [Array, Object],
            required: true,
        },
    },
    components: {
      ConfirmModal
    },
    data() {
        return {
          deleteUserSolutionStatus: apiStatus.Idle,
          openModal: false,
        };
    },
    computed: {
      ...apiStatusComputed("deleteUserSolutionStatus"),
    },
    methods: {
      findIcon(link) {
        if(link.name == 'Facebook') return require('@/assets/socialMediaIcons/facebook.svg')
        if(link.name == 'Youtube') return require('@/assets/socialMediaIcons/youtube.svg')
        if(link.name == 'LinkedIn') return require('@/assets/socialMediaIcons/linkedin.svg')
        if(link.name == 'Twitter') return require('@/assets/socialMediaIcons/twitter.svg')
      },
      deleteSolution() {
        this.openModal = true;
      },
      async deleteUserSolution(solution) {
        this.deleteUserSolutionStatus = apiStatus.Pending;
        
        let payload = {
          id: solution.id
        };

        const {response, error} = await withAsync(deleteSolution, payload);

        if(error) {
          this.deleteUserSolutionStatus = apiStatus.Error
          return;
        }
       this.deleteUserSolutionStatus = apiStatus.Success;
       this.openModal = false;
       this.$emit('solution-deleted', true); 
     },
      editSolution(solution) {
        this.$router.push({ name: "Create - Edit Solution" , params: {id: solution.id} })
      }
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;
}
.title-overflow, .description-overflow, .website-link-overflow {
    width: 80%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.button-hover:hover {
  background: #33CC33 !important;
  color: #fff !important;
  border: none; 
}
.website-link-overflow {
  width: 70%;
}
.image-wrapper::after {
  content: '';
	clear: both;
	display: block;
}
.image-container {
  position: relative;
  overflow: hidden;
  border: none;
}
.image-itself {
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
c
.scale:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transition-duration: .3s;
}
.background-test {
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898 !important;
  background-blend-mode: multiply,multiply !important;
}
.fa-tag {
  font-size: 0.5rem !important;
}
</style>